.rcw-launcher, .rcw-launcher, .rcw-open-launcher, .rcw-close-launcher {
    background-color: #1D428A;
}
.rcw-header .rcw-close-button {
    background-color: #1D428A;
}

.rcw-client .rcw-message-text {
    background-color: #141B4D;
    color: #fff;
}

.rcw-response .rcw-message-text {
    background-color: #A2AAAD;
    color: #000;
}

.rcw-timestamp {
    color: #a2aaad;
    font-weight: bold;
}

.rcw-conversation-container .rcw-header, .rcw-conversation-container .rcw-close {
    background-color: #1D428A;
    padding: 5px 0 15px;
}